.trade-item {
  position: relative;

  .button.show-upgrade-membership-modal {
    padding: 0.15rem 0.25rem;
    display: inline-flex;
    align-items: center;

    i {
      font-size: 1.5em;
      margin-right: 0.25em;
    }
  }

  span.no-access {
    color: transparent;
    background-color: $lighter-gray;
    user-select: none;
    word-wrap: break-word;
    max-width: 100%;
  }

  &.tpl-1 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex: 1 1 100%;
    margin-bottom: 1.5rem;
    position: relative;

    .item-top {
      display: flex;
      margin-bottom: 0.25rem;

      .itl {
        flex: 1 1 auto;

        &>div {
          &:nth-of-type(1) {
            display: flex;
            margin-bottom: 0.25rem;
          }

          &:nth-of-type(2) {
            position: relative;

            .flyout-menu {
              position: absolute;
              top: 0;
              right: -12px;
            }
          }
        }
      }

      .item-image {
        display: none;

        @include breakpoint(480px) {
          display: flex;
          flex: 0 1 auto;
        }
      }
    }

    .item-image {
      position: relative;
      height: auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: $lighter-gray;
      background-size: 100% auto;
      transition: background-size 0.5s ease-out;
      margin-bottom: 0.5rem;

      &:hover {
        background-size: 105% auto;
      }

      &>a {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
      }

      market-item-countdown,
      .deleted-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .deleted-info {
        font-size: 0.85rem;
        text-align: center;
        border-radius: 0.5rem;
        background-color: rgba(0, 0, 0, 0.85);
        color: $white;
        padding: 4px;
      }
    }

    .item-top {
      market-item-countdown,
      .deleted-info {
        display: none !important;
      }
    }

    .item-details {
      flex: 1 1 100%;
      font-size: 0.85rem;
    }

    .item-date,
    .item-type {
      font-size: 0.75rem;
      line-height: 1;
      flex: 1 1 100%;
      max-width: 50%;
    }

    .item-type {
      text-align: right;
    }

    .item-title {
      flex: 1 1 0;
      margin: 0.35rem 0 0.25rem;
      padding-right: 2rem;
      font-weight: bold;

      &>* {
        font-size: 1rem;
      }
    }

    .item-left .item-image {
      min-width: 50px;
      max-width: 50px;
      flex: 1 1 100%;
      align-items: flex-start;
      margin-left: 1rem;
      margin-bottom: 0.5rem;
    }

    .item-left {
      flex: 1 1;
      display: block;
    }

    .item-right {
      display: none;
    }

    .item-row {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      border-bottom: 1px dotted $medium-gray;
      padding: 0.15rem 0;

      &:first-of-type {
        border-top: 1px dotted $medium-gray;
      }

      &:last-of-type {
        margin-bottom: 0.25rem;
      }

    }

    .item-column {
      flex: 1 1 100%;

      &:first-of-type {
        font-weight: bold;
      }
    }

    .item-footer {
      flex: 1 1 100%;
      display: flex;
      justify-content: flex-end;
    }

    &.smart .item-footer {
      display: none;
    }

    .button {
      text-transform: uppercase;
    }

    @include breakpoint(small down) {
      business-group-icon {
        font-size: 9px;
        width: 17px;
        height: 17px;
        position: absolute;
        bottom: -2px;
        right: -4px;
        box-shadow: 1px 1px 0 0 rgba(0, 22, 10, 0.8);
      }

      .bio-icon {
        font-size: 10px;
        line-height: 10px;
        padding: 1px 3px;
        top: 3px;
        box-shadow: 1px 1px 0 0 rgba(0, 22, 10, 0.8);

        i {
          display: none;
        }
      }
    }

    @include breakpoint(420px) {
      .item-row {
        flex-direction: row;
      }

      .item-column {
        flex: 1 1 auto;

        &:first-of-type {
          font-weight: normal;
        }

        &:last-of-type {
          margin-left: 1rem;
          text-align: right;
        }
      }
    }

    @include breakpoint(medium) {
      .item-left {
        .item-image {
          display: none;
        }
      }

      .item-title {
        &>* {
          font-size: 1.1rem;
        }
      }

      .item-right {
        display: block;
        flex: 1 1 auto;
        max-width: 140px;
        margin-left: 1.5rem;

        .item-image {
          margin: 0;
          margin-bottom: 0.5rem;
        }
      }

      .item-footer {
        display: none;
      }
    }
  }

  &.tpl-2 {
    padding: 1rem;
    border-radius: $global-radius;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    background-color: $white;
    transition: background-color .2s ease-in-out;
    margin: 0 0 1rem;
    outline: 0;

    &.highlighted {
      background-color: $mundus-agri-color-light;
    }

    .flyout-menu {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    .bgroup-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 3px;
      right: 3px;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.75);
      cursor: pointer;
    }

    .item-title {
      display: block;
      padding-right: 2.5rem;
      min-height: 38.375px;

      &>* {
        font-size: 1.1rem;
        line-height: 1.2rem;
        font-weight: bold;
      }
    }

    .item-info {
      display: flex;
      color: lighten($body-font-color, 10%);
      font-size: 0.85rem;
      margin-top: 0.25rem;
      margin-bottom: 0.15rem;
    }

    .item-date,
    .item-type {
      flex: 1;
    }

    .item-type {
      text-align: right;
    }

    .item-image {
      display: block;
      position: relative;
      margin-bottom: 0.5rem;
      height: 200px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: $lighter-gray;
      background-size: 100% auto;
      transition: background-size 0.5s ease-out;

      &:hover {
        background-size: 105% auto;
      }

      market-item-countdown {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .item-body {
      margin-bottom: 0.5rem;
      font-size: 0.85rem;
      
      .item-provider {
        a:not([href]) {
          color: $body-font-color;
          cursor: default;
        }
      }

      &>div {
        display: flex;
        border-bottom: 1px dotted $medium-gray;
        padding: 2px 0;

        &>div:nth-of-type(1) {
          flex: 0 1 auto;
          padding-right: 1rem;
          white-space: nowrap;
        }

        &>div:nth-of-type(2) {
          flex: 1 1 auto;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &>company-rating {
          flex: 0 1 auto;
          margin-left: 0.5rem;
        }
      }
    }

    .item-footer {
      .button {
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
}