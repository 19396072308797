.uic-pretty-checkbox {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  input {
    position: absolute;
    z-index: -9999;
    opacity: 0;
  }

  [type=checkbox]+label {
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
  }

  label {
    display: block !important;
    position: relative;
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid $pretty-checkbox-border-color;
    background-color: $pretty-checkbox-background-color;
    border-radius: 0.25em;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
    vertical-align: middle;
    transition: all 75ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 4px;
      width: 11px;
      height: 6px;
      opacity: 0;
      border: 2px solid $pretty-checkbox-checkmark-color;
      border-top: none;
      border-right: none;
      background: transparent;
      transform: rotate(-45deg) scale(0);
      backface-visibility: hidden;
      color: #555;
      overflow: hidden;
      transition: all 75ms ease-in-out;
    }
  }

  input:checked+label:after {
    transform: rotate(-45deg) scale(1);
    opacity: 1;
  }

  &.uic-disabled label,
  input:disabled+label {
    cursor: not-allowed !important;
    border-color: $pretty-checkbox-disabled-border-color !important;
    background-color: $pretty-checkbox-disabled-background-color !important;

    &:after {
      border-color: $pretty-checkbox-disabled-checkmark-color !important;
    }
  }
}

@mixin uic-pretty-checkbox($pretty-checkbox-background-color, $pretty-checkbox-border-color, $pretty-checkbox-checkmark-color) {
  label {
    border-color: $pretty-checkbox-border-color;
    background-color: $pretty-checkbox-background-color;

    &:after {
      border-color: $pretty-checkbox-checkmark-color;
    }
  }
}