.overflow-scroll-x {
  @include overflowScrollX();
}

.overflow-scroll-y {
  @include overflowScrollY();
}

.box-shadow {
  @include boxShadow();
}

.flex {
  display: flex !important;

  &.align-middle {
    align-items: center;
  }

  &.align-top {
    align-items: flex-start;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-dir-column,
  &.column {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.justify {
    justify-content: space-between;
  }

  &>.shrink {
    flex: 0 1 auto;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

.justify-flex-between {
  justify-content: space-between !important;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.no-padding {
  padding: 0 !important;
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0 !important;
}

.no-flex-wrap {
  flex-wrap: nowrap !important;
}

.no-border {
  border: 0 !important;
}

.margin-top {
  margin-top: 1rem !important;
}

.margin-bottom {
  margin-bottom: 1rem !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.max-small {
  max-width: 480px !important;
}

.max-medium {
  max-width: map-get($breakpoints, medium) !important;
}

.max-mlarge {
  max-width: map-get($breakpoints, mlarge) !important;
}

.max-large {
  max-width: map-get($breakpoints, large) !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.relative {
  position: relative !important;
}

@media print {
  .hide-for-print {
    display: none !important;
  }
}

@media not print {
  .show-for-print {
    display: none !important;
  }
}