@keyframes showDropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
    border: $input-border;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    border: $input-border-focus;
  }
}

.single-select-dropdown {
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
  min-width: 0;
  padding: $input-padding 1.5rem $input-padding $input-padding;
  white-space: normal;
  font-size: 0.9rem;
  outline: 0;
  min-height: 2.2875rem;
  margin-bottom: 1rem;
  background: $white;
  border: $input-border;
  background-color: $white;
  transition: border-color 150ms ease-in-out;
  background-image: url($dropdown-arrow);
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  cursor: default;
  user-select: none;

  select {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }

  input.search {
    position: absolute;
    padding: inherit;
    width: 100%;
    line-height: 1rem;
    background: none transparent;
    border: none;
    box-shadow: none;
    cursor: text;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    display: block;

    &[readonly] {
      cursor: default;
    }
  }

  .item-list {
    max-height: 16rem;
    opacity: 0;
    display: none;
    cursor: auto;
    position: absolute;
    left: 0;
    top: 100%;
    background: $white;
    border: $input-border;
    border-top-width: 0 !important;
    width: calc(100% + 2px);
    transform-origin: top center;
    transform: scaleY(0);
    margin: -1px -1px 0;
    z-index: 9;

    @include boxShadow();
    @include overflowScrollY();

    overflow-x: hidden;
    scroll-behavior: auto;
  }

  .item,
  .message {
    padding: 0.5rem $input-padding;
    line-height: 1;
    border-top: 1px solid $lighter-gray;
  }

  .item {
    cursor: default;

    &.filtered,
    &.selected {
      display: none;
    }

    &:hover {
      background-color: $light-gray;
    }

    &.active {
      background-color: $lighter-gray;
    }
  }

  .text {
    position: relative;
    z-index: 3;
    top: 0;
    left: 0;
    max-width: 100%;
    line-height: 1rem;
    cursor: default;

    span {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }

    &.filtered {
      display: none;
    }
  }

  &.open {
    border: $input-border-focus;

    .item-list {
      display: block;
      animation-name: showDropdown;
      animation-duration: 250ms;
      animation-fill-mode: forwards;
    }
  }

  &.search {
    cursor: text;

    input.search {
      position: relative;
      padding: 0;
    }

    .text {
      position: absolute;
      padding: inherit;
      cursor: text;
    }

    &.open {
      .text.default {
        color: $medium-gray;
      }
    }
  }
}