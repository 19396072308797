.login-box {
  .login-extra {
    p {
      font-size: 0.85rem;
      line-height: 1.3;
    }
  }

  @include breakpoint(medium down) {
    button[type="submit"] {
      margin-bottom: 1rem;
    }
  }
}