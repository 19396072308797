.uic-pretty-radio {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  input {
    position: absolute;
    z-index: -9999;
    opacity: 0;
  }

  [type=radio]+label {
    margin: 0;
    padding: 0;
    vertical-align: middle;
    position: relative;
  }

  label {
    display: block !important;
    position: relative;
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid $pretty-radio-border-color;
    background-color: $pretty-radio-background-color;
    border-radius: 20px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    transition: all 75ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      opacity: 0;
      border-radius: 10px;
      background: transparent;
      overflow: hidden;
      transition: all 75ms ease-in-out;
    }
  }

  input:checked+label {
    background-color: $pretty-radio-background-color;
  }

  input:checked+label:after {
    opacity: 1;
    background-color: $pretty-radio-bullet-color;
  }

  &.uic-disabled {
    label {
      cursor: not-allowed !important;
      border-color: lighten($pretty-radio-border-color, 15%) !important;
      background-color: darken($pretty-radio-background-color, 15%) !important;

      &:after {
        border-color: lighten($pretty-radio-bullet-color, 15%) !important;
      }
    }
  }
}

@mixin uic-pretty-radio($pretty-radio-background-color, $pretty-radio-border-color, $pretty-radio-bullet-color) {
  label {
    border-color: $pretty-radio-border-color;
    background-color: $pretty-radio-background-color;

    &:after {
      border-color: $pretty-radio-bullet-color;
    }
  }

  input:checked+label {
    background-color: $pretty-radio-background-color;
  }

  input:checked+label:after {
    opacity: 1;
    background-color: $pretty-radio-bullet-color;
  }
}