@import '../mixins';

.uic-multi-checkbox-dropdown {
  * {
    box-sizing: border-box;
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  width: 100%;
  background-color: $white;
  border: 1px solid $multicheckbox-border-color;
  line-height: 1em;
  padding: 0;
  display: block;
  position: relative;
  text-align: left;
  border-radius: $multicheckbox-border-radius;
  transition: border-color 250ms ease-in-out;
  user-select: none;

  .uic-label {
    width: 100%;
    height: $multicheckbox-input-height;
    color: $body-font-color;
    position: relative;
    line-height: 1.5;
    padding: $multicheckbox-padding;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5em;
      user-select: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: $multicheckbox-caret-color transparent transparent transparent;
      transition: all 150ms ease-in-out;
    }
  }

  &[data-uic-hide-label="true"] {
    .uic-label {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: -1;
      top: -9999px;
      left: -9999px;
    }
  }

  .uic-input-wrap {
    cursor: pointer;
    overflow: hidden;
    padding: 0;

    input {
      width: 100%;
      padding: $multicheckbox-padding;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background: $white;
      padding-top: 0.75rem;
      box-shadow: none;
      padding-bottom: 0.75rem;
      margin: 0;

      &:focus {
        background: $white;
        box-shadow: none;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  &.uic-expanded .uic-input-wrap input {
    cursor: text;
  }

  .uic-menu {
    position: absolute;
    overflow: hidden;
    z-index: 10;
    top: 100%;
    left: -1px;
    background-color: $white;
    border: 1px solid $multicheckbox-expanded-border-color;
    border-bottom-width: 0;
    border-top-width: 0;
    width: calc(100% + 2px) !important;
    opacity: 0;
    display: none;
    transition: opacity 100ms ease-in-out;

    &.uic-measuring {
      position: absolute;
      visibility: hidden;
      display: block;
    }

    .uic-submenu {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #f3f3f3;
      }

      >div:first-child {
        flex: 0 0 auto;
        padding: $multicheckbox-menu-item-padding $multicheckbox-padding/2 $multicheckbox-menu-item-padding $multicheckbox-padding;
        line-height: 0;
        margin-top: -1px;
        align-self: center;

        input {
          outline: 0;
          line-height: 0;
          margin: 0;
        }
      }

      >div:last-child {
        flex: 1 1 100%;

        label {
          font-weight: bold;
          padding: $multicheckbox-menu-item-padding $multicheckbox-padding;
          line-height: 1;
          cursor: pointer;
        }
      }
    }

    .uic-scrollable {
      width: 100%;
      height: auto;
      max-height: 15rem;
      overflow-x: hidden;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
    }

    .uic-option-row {
      flex: 0 0 100%;
      overflow: hidden;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      line-height: 1em;
      cursor: pointer;

      &:hover {
        background: #f3f3f3;
      }

      >div:first-child {
        flex: 0 0 auto;
        padding: $multicheckbox-menu-item-padding $multicheckbox-padding/2 $multicheckbox-menu-item-padding $multicheckbox-padding;
        line-height: 0;

        input {
          outline: 0;
          line-height: 0;
          margin: 0;
        }
      }

      >div:last-child {
        flex: 1 1 100%;

        label {
          padding: $multicheckbox-menu-item-padding $multicheckbox-padding;
          line-height: 1;
          cursor: pointer;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  &[data-uic-hide-select-all="true"] .uic-menu .uic-submenu {
    display: none !important;
  }

  .uic-message {
    display: none;
    user-select: none;
    padding: 0 $multicheckbox-padding $multicheckbox-padding;
    line-height: 1em;
    color: $multicheckbox-message-color;
  }

  &.uic-expanded,
  &[data-uic-dropdown-style="none"] {
    border-bottom-color: transparent;
    border-color: $multicheckbox-expanded-border-color;

    .uic-label {
      color: $body-font-color;
      background: #f8f8f8;

      &:after {
        transform: rotate(180deg);
      }

      &:hover {
        color: $body-font-color;
      }
    }

    .uic-menu {
      opacity: 1;
      border-bottom-left-radius: $multicheckbox-border-radius;
      border-bottom-right-radius: $multicheckbox-border-radius;
    }
  }

  &[data-uic-option-columns="2"] .uic-menu .uic-option-row,
  &[data-uic-option-columns="3"] .uic-menu .uic-option-row,
  &[data-uic-option-columns="4"] .uic-menu .uic-option-row,
  &[data-uic-option-columns="5"] .uic-menu .uic-option-row,
  &[data-uic-option-columns="6"] .uic-menu .uic-option-row {
    >div:last-child label {
      padding-right: 1.5em;
    }
  }

  &[data-uic-option-columns="2"] .uic-menu .uic-option-row {
    flex: 0 0 50%;
  }

  &[data-uic-option-columns="3"] .uic-menu .uic-option-row {
    flex: 0 0 33.33%;
  }

  &[data-uic-option-columns="4"] .uic-menu .uic-option-row {
    flex: 0 0 25%;
  }

  &[data-uic-option-columns="5"] .uic-menu .uic-option-row {
    flex: 0 0 20%;
  }

  &[data-uic-option-columns="6"] .uic-menu .uic-option-row {
    flex: 0 0 16.66%;
  }

  &[data-uic-dropdown-style="accordion"] .uic-menu {
    position: relative;
    z-index: 1;
  }

  &[data-uic-dropdown-style="dropdown"] {
    overflow: visible;
    height: $multicheckbox-input-height;

    &.uic-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .uic-menu {
      border-bottom-width: 1px;
    }
  }

  &[data-uic-has-search-input="false"] .uic-input-wrap input {
    display: none;
  }

  &[data-uic-dropdown-style="none"] {
    .uic-label {
      cursor: default;

      &:after {
        display: none;
      }
    }

    .uic-menu {
      position: relative;
      opacity: 1;
      display: block;
      z-index: 1;
      top: initial;
    }
  }

  &:not([data-uic-maxrows="0"]) {
    .uic-scrollable {
      overflow-y: auto;

      @include overflowScrollY();
    }
  }

  &.uic-is-disabled {
    background-color: #e6e6e6;

    .uic-label {
      cursor: default;
      color: #aaa;

      &:hover {
        color: #aaa;
      }
    }
  }
}