.dh-toggle.events {
  position: relative;

  .num-notifications {
    background-color: get-color(alert);
    color: #fff;
    border-radius: 2px;
    padding: 2px;
    line-height: 1;
    font-size: 10px;
    position: absolute;
    display: none;
    top: -5px;
    left: calc(100% - 8px);
  }

  .date-time {
    color: $medium-gray;
  }

  &.has-notifications .num-notifications {
    display: block;
  }
}

.dh-flyout.events {
  .dh-flyout-content {
    max-height: 13rem;
    @include overflowScrollY();
  }

  .no-results-message {
    white-space: normal;
    cursor: default;
    font-size: 14px;
    padding: 0.5rem;
    border-left: 0.5rem solid $light-gray;
  }

  .notifications-list {
    &>a:not([href]) .action {
      color: $body-font-color;
    }

    &>a,
    &>div {
      display: block;
      white-space: normal;
      cursor: default;
      font-size: 0.95rem;
      padding: 0.5rem 0 0.5rem 0.5rem;
      border-left: 0.5rem solid $light-gray;
      transition: background-color 150ms ease-in-out;
      border-left-color: $mundus-agri-color;

      &:not(:last-child) {
        border-bottom: 1px dotted $medium-gray;
      }

      .date-time {
        font-size: 0.8rem;
        display: block;
        color: $dark-gray;
      }

      &.clicked {
        border-left-color: $medium-gray;
        background-color: transparent;
      }

      &.linked {
        cursor: pointer;

        .action {
          color: $mundus-agri-color;
        }

        &:hover {
          .action {
            color: $anchor-color-hover;
          }
        }
      }
    }
  }
}

#modal-notifications {
  .boxes {
    display: flex;
    flex-wrap: wrap;

    a {
      display: block;
      position: relative;
      flex: 1 1 100%;
      max-width: calc(100% / 2);
      margin-top: -1px;
      margin-left: -1px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-top: 0;
      }

      &:nth-of-type(2n+1) {
        margin-left: 0;
      }

      i,
      b {
        font-size: 1.25rem;
      }

      svg {
        max-height: 1.25rem;
      }

      span {
        display: block;
        margin-top: 0.5rem;
        line-height: 1.1;
      }
    }
  }

  @include breakpoint(medium) {
    .boxes {
      a {
        max-width: calc(100% / 3);
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &:nth-of-type(3) {
          margin-top: 0;
        }

        &:nth-of-type(2n+1) {
          margin-left: -1px;
        }

        &:nth-of-type(3n+1) {
          margin-left: 0;
        }

        i,
        b {
          font-size: 2.25rem;
        }

        svg {
          max-height: 2.25rem;
        }

        span {
          margin-top: 1rem;
        }
      }
    }
  }
}

#modal-notification-settings {
  .modal-inner {
    max-width: 900px;
  }

  .modal-head {
    padding: 1rem;
    background-color: $light-gray;
    color: $mundus-agri-color;

    h3 {
      margin: 0;
    }
  }

  .modal-body {
    padding: 1rem;

    ul li {
      display: flex;
      align-items: center;

      &>div {
        margin-right: 1rem;
      }
    }
  }
}