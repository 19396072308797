@keyframes stocksTickerAnimation {
  from {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

.stock-exchange-bar {
  height: 58px;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  background-color: $white;
  border: 1px solid $light-gray;
  padding: 0.35rem 0.5rem 0.25rem;
  font-size: 0.80rem;
  position: relative;

  .stocks-ticker {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
  }

  .ticker-data {
    animation-name: stocksTickerAnimation;
    animation-duration: 240s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-animation-name: stocksTickerAnimation;
    -webkit-animation-duration: 240s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    display: flex;
    padding-left: 100%;
    flex: 1 1 100%;
    justify-content: space-between;
    background-color: $white;
    padding: 0.35rem 0 0.25rem;
    font-size: 0.80rem;
    position: relative;
  }

  .stock-item {
    padding-left: 16px;
    position: relative;
    // border-right: 1px solid $light-gray;
    // padding-right: 1rem;
  }

  .devider {
    display: block;
    flex: 0 1 auto;
    width: 1px;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 100%;
    background-color: $light-gray;
  }

  .stock-title:before {
    margin-top: 4px;
    position: absolute;
    margin-left: -4px;
    transform: translateX(-100%);
  }

  .stock-title {
    a {
      color: $black;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .stable .stock-title:before {
    margin-top: 2px;
  }

  .stock-details {
    display: flex;
    justify-content: space-between;
  }

  .stock-title {
    font-weight: bold;
    flex: 1 1 100%;
  }

  .stock-price,
  .stock-change {
    flex: 0 1 auto;
  }

  .stock-price {
    margin-right: 0.5rem;
  }

  .positive {
    .stock-title::before {
      @include css-triangle(6px, get-color(success), up);
    }

    .stock-change {
      color: get-color(success);
    }
  }

  .stable {
    .stock-title::before {
      @include css-triangle(6px, $dark-gray, right);
    }
  }

  .negative {
    .stock-title::before {
      @include css-triangle(6px, get-color(alert), down);
    }

    .stock-change {
      color: get-color(alert);
    }
  }
}