@keyframes showSubMenu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#main-menu {
  width: 100%;
  position: relative;
  user-select: none;

  .horizontal-menu-bar {
    margin-bottom: 0;
    position: relative;
    box-shadow: none;
    align-items: stretch;

    &>li {
      display: flex;
      flex: 1 1 auto;
      align-items: center;

      &:not(:first-child) {
        margin-left: 1px;
      }

      &:last-child {
        flex: 0 1 auto;
      }

      &.visible {
        position: static;
      }

      a.highlight {
        color: $white;
        background-color: $highlight-color;
      }

      &>a, .flyout-trigger {
        text-align: center;
        border: 1px solid transparent;
        border-bottom: 0;
        color: $mundus-agri-color;
        background-color: $lighter-gray;
        transition: all 250s ease-out;
        border-radius: 0;

        &:hover {
          color: $flyout-trigger-hover-color;
        }
      }

      &>a {
        width: 100%;
      }
    }
  }

  .flyout-menu li a {
    font-size: 0.9rem;
  }

  .menu-item {
    transition: $button-transition;

    &:first-of-type {
      margin-left: 0;
    }

    &.active>a {
      font-weight: 900;
    }

    &>a {
      display: block;
      text-align: left;
      padding: 0.3rem 1rem;

      &>span {
        margin: 0;
      }

      &:not(.button) {
        color: $mundus-agri-color;
        transition: $button-transition;

        span {
          font-size: 0.9rem;
        }

        &:hover {
          color: $mundus-agri-color;
          text-decoration: underline;
        }
      }

      &:not([href]) {
        cursor: pointer;
      }
    }

    &.hover {
      &>a:not(.button) {
        transition: all 250ms ease;
      }

      &.has-submenu {
        &>a:not(.button) {
          color: $primary-color;
          border-color: $medium-gray;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          box-shadow: 0 1px 0 0 $white;
          background-color: lighten($lighter-gray, 2%);
        }

        .drop {
          display: flex;
          animation-name: showSubMenu;
          animation-duration: 150ms;
          animation-fill-mode: forwards;
          animation-play-state: running;
        }
      }
    }

    .button {
      @include button-style($highlight-color, scale-color($highlight-color, $lightness: -15%), $white);

      height: auto;
      font-size: 0.9rem;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      padding: 0.3rem 1rem;
      margin: 0;
    }
  }

  .drop {
    position: absolute;
    z-index: -1;
    top: 100%;
    background-color: $white;
    border: 1px solid $medium-gray;
    opacity: 0;
    display: none;
    flex-wrap: wrap;
    padding: 0.5rem 0;

    @include boxShadow();
  }

  .submenu-group {
    padding: 0.5rem 0;

    &>b {
      display: block;
      text-align: left;
      padding: 0 1rem 0.25rem;
      margin-top: 0.25rem;
    }

    &>div {
      display: flex;
      flex-wrap: wrap;
    }

    .menu-item-col {
      flex: 0 1 auto;
      padding: 0 1rem;
    }

    a {
      display: flex;
      margin-bottom: 0.25rem;
      text-align: left;
      font-size: 0.9rem;

      i {
        font-style: normal;
        margin-right: 0.5rem;
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}