//  UI Collection Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Checkboxes
//   2. Radio Buttons
//   3. Breadcrumbs
//   4. Progress
//   5. Spinner
//   6. Spinner Overlay
//   7. Two List Filter
//   8. Multi Checbox Dropdown


// 1. Checkboxes
// ---------

$pretty-checkbox-background-color: #fff !default;
$pretty-checkbox-checkmark-color: $body-font-color !default;
$pretty-checkbox-border-color: $medium-gray !default;
$pretty-checkbox-disabled-border-color: lighten($pretty-checkbox-border-color, 15%) !default;
$pretty-checkbox-disabled-background-color: darken($pretty-checkbox-background-color, 15%) !default;
$pretty-checkbox-disabled-checkmark-color: lighten($pretty-checkbox-checkmark-color, 15%) !default;

// 2. Radio Buttons
// ---------

$pretty-radio-background-color: #fff !default;
$pretty-radio-bullet-color: $body-font-color !default;
$pretty-radio-border-color: $medium-gray !default;

// 3. Breadcrumbs
// ---------

$breadcrumb-devider-color: #ccc !default;

// 4. Progress
// ---------

$progress-num-steps: 3 !default;
$progress-breakpoint-width: 800px !default;
$progress-border-color: rgba(34, 36, 38, 0.15) !default;

// 5. Spinner
// ---------

$spinner-diameter: 1rem !default;
$spinner-border-width: 3px !default;
$spinner-circle-color: rgba($mundus-agri-color, 0.35) !default;
$spinner-color: rgba(255, 255, 255, 0.5) !default;

// 6. Spinner Overlay
// ---------

$spinner-overlay-diameter: 2em !default;
$spinner-overlay-border-width: $spinner-border-width !default;
$spinner-overlay-circle-color: $spinner-circle-color !default;
$spinner-overlay-color: $spinner-color !default;
$spinner-overlay-dimmer-background-color: rgba(255, 255, 255, 0.7) !default;
$spinner-overlay-dimmer-font-color: #333 !default;

// 7. Two List Filter
// ---------

$two-list-size: 8 !default;
$two-list-link-color: $mundus-agri-color !default;
$two-list-link-hover-color: $mundus-agri-color-light !default;
$two-list-selected-backgroundcolor: $two-list-link-color !default;
$two-list-active-button-background-color: $two-list-link-color !default;
$two-list-breakpoint-width: 800px !default;

// 8. Multi Checkbox Dropdown
$multicheckbox-input-height: 2.54167rem !default;
$multicheckbox-border-color: $medium-gray !default;
$multicheckbox-expanded-border-color: $medium-gray !default;
$multicheckbox-padding: 0.55556rem !default; // only 1 value allowed
$multicheckbox-border-radius: 0 !default;
$multicheckbox-menu-item-padding: 0.5em !default;
$multicheckbox-message-padding: 0.5em !default;
$multicheckbox-caret-color: $medium-gray !default;
$multicheckbox-caret-hover-color: $medium-gray !default;
$multicheckbox-message-color: $medium-gray !default;