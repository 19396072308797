#back-button-bar {
  width:100%;
  order:100;
  background-color:$light-gray;
  min-height:1rem;
  z-index: 9;
  padding:0.35rem 0.5rem;
  display:none!important;

  .back-button {
    color:$body-font-color;
    font-size:0.9rem;
    font-weight:bold;
    text-transform:uppercase;

    i {
      margin-right:0.15rem;
      font-size:1rem;
    }
  }
}