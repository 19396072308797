#modal-create-offer {
  .option-row {
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin-bottom:1rem;

    &>div {
      flex:1;

      &:first-of-type {
        margin-right:1rem;
      }
    }
  }
}