.component.related-addresses {
  .sub-headline {
    font-weight:bold;
    padding-bottom:0.5rem;
    margin-bottom:0.5rem;
    border-bottom:1px dotted $black;
  }

  .component-item {
    font-size:0.8rem;
	
    &:not(:last-of-type) {
      padding-bottom:0.5rem;
      border-bottom:1px solid $medium-gray;
      margin-bottom:0.5rem;
    }
    
    .item-country, .item-category {
      font-weight:normal;
      font-size:0.7rem;
      margin-top:0.25rem;
    }
  }
}