@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import '~application/node_modules/foundation-sites/scss/foundation';

.horizontal-menu-bar {
  display: flex;
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
  box-shadow: inset 0 -1px 0 0 $light-gray;

  &>li {
    flex: 0 1 auto;
    position: absolute;
    z-index: -100;
    visibility: hidden;
    opacity: 0;

    &>a {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      background-color: $white;
      color: $body-font-color;
      border: 1px solid $light-gray;
      transition: $button-transition;

      i {
        text-align: center;
        margin-right: 0.5rem;
      }

      span {
        display: inline-flex;
        flex: 1;
        align-items: center;
        line-height: 1;
        font-size: 0.85rem;
        margin-top: 0.25rem;
        text-align: center;
        white-space: nowrap;
      }

      &:hover {
        color: $primary-color;
      }

      &.active {
        background-color: $mundus-agri-color-light;
        pointer-events: none;
      }
    }

    &:not(:last-child) {
      flex: 1 1 auto;

      @include breakpoint(480px) {
        flex: 0 1 auto;
      }
    }

    &:not(:first-child) {
      a {
        border-left: 0;
      }
    }
  }

  &.measuring {
    &>li {
      flex: 0 1 auto !important;
    }
  }

  &.has-flyout {
    &>li:not(:last-child) {
      flex: 1 1 auto;
    }
  }

  .flyout-menu {
    height: 100%;

    li {
      position: absolute;
      z-index: -100;
      visibility: hidden;
      opacity: 0;
    }

    .flyout-trigger {
      display: flex;
      align-items: center;
      height: 100%;
      border-radius: 0;
      color: $body-font-color;
      transition: $button-transition;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      background-color: $white;
      border: 1px solid $light-gray;
      border-left: 0;

      i {
        margin: 0;
      }

      &:hover {
        color: $primary-color;
        background-color: $white;
      }
    }

    &.is-open .flyout-trigger {
      background-color: $white;
    }
  }

  li.visible {
    position: relative;
    z-index: initial;
    visibility: visible;
    opacity: 1;
  }
}