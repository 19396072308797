@keyframes animateSearchIcon {
  0% {
    transform: scale(1) rotate(0);
  }

  100% {
    transform: scale(1) rotate(720deg);
  }
}

.search-bar-container {
  position: relative;

  .search-bar {
    width: 100%;
    margin: auto;
    display: flex;
  }

  .input-wrap {
    flex: 1;
    position: relative;

    span {
      position: absolute;
      display: none;
      z-index: 2;
      top: calc(100% + 1px);
      background-color: get-color(alert);
      color: $white;
      padding: 2px 6px;
      line-height: 12px;
      left: 0;
      font-size: 11px;
      border-radius: 2px;

      &::after {
        content: '';
        position: absolute;
        left: 8px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 6px 5px;
        border-color: transparent transparent get-color(alert) transparent;
      }
    }
  }

  input,
  input:focus {
    height: 100%;
    box-shadow: none;
    margin-bottom: 0;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 2px solid $primary-color;
    border-bottom: 2px solid $primary-color;
    border-left: 2px solid $primary-color;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    background-color: $white;
    box-shadow: 0 0 0px 1000px white inset;

    &:-webkit-autofill {
      background-color: $white !important;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    &:focus+span {
      display: none !important;
    }
  }

  .results {
    position: absolute;
    z-index: 101;
    display: none;
    top: 100%;
    background-color: $white;
    box-shadow: 0 1px 2px 0 rgba(11, 11, 11, .25);
    flex-wrap: wrap;

    &>div {
      padding: 0.5rem 0.5rem;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      &>b {
        padding: 0.15rem 0.25rem;
      }
    }

    a {
      flex: 0 1 auto;
      padding: 0.15rem 0.25rem;
      transition: backgroundColor 150ms ease-out;

      &:hover,
      &.focus {
        background-color: $mundus-agri-color-light;
      }
    }
  }

  button {
    flex: 0 1 auto;
    margin: 0;
    height: 43px;
    overflow: hidden;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    i {
      transition: color 250ms ease-in-out;
    }

    span {
      display: none;
    }
  }

  @include breakpoint(480px) {
    input,
    input:focus {
      border-top-left-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem;
    }

    button {
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;

      i {
        margin-right: 0.5rem;
      }
    }
  }

  @include breakpoint(900px) {
    button {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      span {
        display: inline;
      }
    }
  }

  &.is-open {
    .results {
      display: flex;
    }
  }

  &.has-input {
    .search-bar {
      button {
        i {
          color: $highlight-color;
          animation-name: animateSearchIcon;
          animation-duration: 500ms;
          animation-fill-mode: forwards;
        }
      }
    }

    .input-wrap span {
      display: none !important;
    }
  }
}