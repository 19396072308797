html.lock-position {
  overflow-x: hidden;
  overflow-y: hidden;
}

.sb-menu-trigger {
  margin-left: 0.65rem;
}

.sidebar-menu {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 2199999999; // cover user-like

  transition: visibility 200ms linear;

  &.sb-visible {
    visibility: visible;
  }

  .sb-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    will-change: transform;
    transition: transform 200ms ease-in-out 100ms;
  }

  .sb-closer {
    position: absolute;
    top: 0.75rem;
    left: -0.75rem;
    width: 2rem;
    height: 2rem;
    transform: translateX(-100%);
    opacity: 0.75;
    will-change: opacity;
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      content: ' ';
      height: 2rem;
      width: 2px;
      background-color: $white;
      left: 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .sb-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 365px;
    max-width: calc(100% - 3rem);
    background-color: $white;
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.33);
    will-change: transform;
    transform: translateX(100%);
    transition: transform 200ms ease-in-out 100ms;
  }

  .sb-top {
    background-color: $light-gray;
    padding: 0.75rem 1rem;
    position: relative;
    display: flex;

    &>div:nth-of-type(2) {
      flex: 1;
    }

    .user-profile-image {
      display: inline-block;
      margin-right: 0.5rem;

      a {
        display: block;

        i {
          font-size: 40px;
          width: 38px;
          height: 38px;
          border-radius: 200px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.75);
        }
      }

      a.has-image {
        width: 38px;
        height: 38px;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 200px;
        box-shadow: 0 0 0 3px #fff, 0 1px 2px 3px rgba(0, 0, 0, 0.75);

        &[data-abo-model-id="4"] {
          box-shadow: 0 0 0 3px $abo-bronze-color, 0 1px 2px 3px rgba(0, 0, 0, 0.75);
        }

        &[data-abo-model-id="2"] {
          box-shadow: 0 0 0 3px $abo-silver-color, 0 1px 2px 3px rgba(0, 0, 0, 0.75);
        }

        &[data-abo-model-id="3"] {
          box-shadow: 0 0 0 3px $abo-gold-color, 0 1px 2px 3px rgba(0, 0, 0, 0.75);
        }
      }
    }

    .user-company {
      font-size: 0.85rem;
    }

    .button-logout {
      margin-left: 0.5rem;
      white-space: nowrap;
    }
  }

  .sb-menus {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .sb-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    opacity: 1;
    list-style: none;
    height: 100%;
    width: 100%;
    will-change: transform;
    transform: translateX(100%);
    transition: transform 200ms ease-in-out 100ms, opacity 100ms ease-in-out 50ms;

    @include overflowScrollY();
  }

  .sb-menu-top {
    display: block;
    padding: 1rem;
    border-bottom: 1px solid $light-gray;
    text-transform: uppercase;
    font-weight: bold;
    transition: $button-transition;

    i {
      margin-right: 0.5rem;
    }

    &:hover {
      background-color: $mundus-agri-color-light;
    }
  }

  .sb-groups {
    width: 100%;
    padding: 0 0 1rem;
  }

  .sb-group {
    padding: 1rem 0;
    margin: 0;
    list-style: none;

    &:not(:last-of-type) {
      border-bottom: 1px dotted $light-gray;
    }
  }

  .sb-group-title {
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $secondary-color;
  }

  .sb-item {
    display: block;
    padding: 0.2rem 1.5rem;
    position: relative;
    transition: $button-transition;

    &[data-target-menu-id] {
      &::after {
        position: absolute;
        right: 1rem;
        top: 50%;
        margin-top: -5px;
        @include chevron-right($secondary-color, 8px, 8px, 1px);
      }

      &:hover::after {
        border-color: $mundus-agri-color;
      }
    }
  }

  a.sb-item:hover {
    background-color: $mundus-agri-color-light;
  }

  .sb-fade-in {
    opacity: 1;
  }

  .sb-fade-out {
    opacity: 0;
  }

  .sb-center {
    transform: translateX(0);
    opacity: 1;
  }

  .sb-left {
    transform: translateX(-100%);
    opacity: 0;
  }
}