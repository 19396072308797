@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import '~application/node_modules/foundation-sites/scss/foundation';
@import 'ui-collection/uic-settings';
@import '~application/node_modules/@fortawesome/fontawesome-free/scss/_functions.scss';

$flag-icon-css-path: '~application/node_modules/flag-icon-css/flags';
@import '~application/node_modules/flag-icon-css/sass/flag-icon';

@import 'components/news';
@import 'components/offers';
@import 'components/related-addresses';

// Util Tabs Config
$tabs-breakpoints: (0,
  320px,
  480px,
  570px,
  660px,
  680px,
  750px,
  840px,
  1000px);
$tabs-border-radius: $global-radius;
$tabs-label-color: $body-font-color;
$tabs-label-active-color: $white;
$tabs-label-background-color: $mundus-agri-color-light;
$tabs-label-active-background-color: $mundus-agri-color;
$tabs-label-border-color: transparent;
$tabs-label-arrow-color: $body-font-color;
$tabs-label-active-arrow-color: $white;
$tabs-label-space-above: 1px;
$tabs-label-space-between: 3px;
$tabs-content-border-color: $mundus-agri-color;
$tabs-content-border-width: 1px 0 0;

$activeButtonBackgroundColor: $mundus-agri-color;
@import 'util/confirm-overlay';
@import 'util/flyout-menu';
@import 'util/modal-window';
@import 'util/search-input';
@import 'util/single-select-dropdown';
@import 'util/multi-select-dropdown';
@import 'util/pagination';
@import 'util/spinner';
@import 'util/spinner-overlay';
@import 'util/tabs';
@import 'util/textarea';
@import 'util/horizontal-menu-bar';

@import 'ui-collection/notifications';
@import 'ui-collection/spinner';
@import 'ui-collection/multi-checkbox-dropdown';
@import 'ui-collection/pretty-checkboxes';
@import 'ui-collection/pretty-radio-buttons';

@import 'general';
@import 'dedere-header/dedere-header';
@import 'terms/terms';
@import 'modal/modal-create-offer';
@import 'ostentus/ostentus-banner';
@import 'cookie/cookie-notice';
@import 'login';
@import 'footer';
@import 'helper-classes';
@import 'icon-font';

@import '~application/src/scss/trade-items/trade-item';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.1/css/all.min.css');