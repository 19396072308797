.terms-and-conditions {
	h2 {
		margin-top:2.5rem;
	}

	h3 {
		margin-top:1.75rem;
	}

	ul {
		padding-left:1.25rem;
		margin-bottom:1rem;
	}

	table tr {
		&:nth-child(odd) {
			background:#fafafa;
		}

		&:nth-child(even) {
			background:#f2f2f2;
		}
	}
}