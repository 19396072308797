// .dh-toggle.language {
//   display: none!important;

//   @include breakpoint(480px) {
//     display: flex!important;
//   }
// }

.dh-flyout.language {
  .dh-flyout-content {
    a {
      display: flex;
      align-items: center;

      &:hover {
        &>span,
        &>i {
          color: $anchor-color-hover;
        }
      }
    }

    img {
      height: 1em;
      margin-right: 0.5rem;
      display: block;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.33);
    }
  }
}