.ostentus-banner {
	display: inline-block;
	margin: 0 0.5em;
	text-align: left;

	a.ostentus-advertisement-link {
		color: #000;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	small {
		font-size: 10px;
	}
}