@import 'variables';
@import 'settings';
@import '~application/node_modules/foundation-sites/scss/foundation';

@font-face {
  font-family: 'HelveticaNeueLTStd-Th';
  src: url('~application/src/fonts/HelveticaNeueLTStd-Th.eot');
  /* IE9 Compat Modes */
  src:
    url('~application/src/fonts/HelveticaNeueLTStd-Th.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('~application/src/fonts/HelveticaNeueLTStd-Th.otf') format('opentype'),
    /* Open Type Font */
    url('~application/src/fonts/HelveticaNeueLTStd-Th.svg') format('svg'),
    /* Legacy iOS */
    url('~application/src/fonts/HelveticaNeueLTStd-Th.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('~application/src/fonts/HelveticaNeueLTStd-Th.woff') format('woff'),
    /* Modern Browsers */
    url('~application/src/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
  height: auto;
  overflow-x: hidden;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  overflow-x: hidden;

  &>main,
  &>.main-content {
    min-height: 300px;
  }
}

@media (min-height:600px) {
  body {

    &>main,
    &>.main-content {
      min-height: 400px;
    }
  }
}

@media (min-height:800px) {
  body {

    &>main,
    &>.main-content {
      min-height: 600px;
    }
  }
}

// hide weird unknown overlay
#OP_lyrMain,
.OP_lyrMain {
  display: none !important;
}

main,
.upper-footer,
.main-footer,
.lower-footer {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.box-head {
  @include boxHead();
}

.content-box {
  @include contentBox();

  &.info {
    background-color: scale-color(get-color(info), $lightness: $callout-background-fade);
  }

  &.success {
    background-color: scale-color(get-color(success), $lightness: $callout-background-fade);
  }

  &.alert {
    background-color: scale-color(get-color(alert), $lightness: $callout-background-fade);
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  &.thin,
  &.thin-headline,
  &.page-headline {
    @include thinHeadline();
    margin-bottom: 1rem;

    i {
      color: $mundus-agri-color;
      margin-right: 0.25rem;
    }

    img {
      margin-right: 0.25rem;
      height: 2.5rem;
      margin-top: -4px;
    }
  }
}

.callout {
  &.dark {
    background-color: $mundus-agri-color-dark;
    color: $white;
    border: 0;
  }

  &.info {
    border-color: lighten(get-color(info), 40%);
  }

  &.alert {
    border-color: lighten(get-color(alert), 40%);
  }

  &.warning {
    border-color: lighten(get-color(warning), 30%);
  }

  &.success {
    border-color: lighten(get-color(success), 40%);
  }
}

.button,
button {
  outline: 0;

  &.highlight {
    @include button-style($highlight-color, scale-color($highlight-color, $lightness: -15%), $white);
  }

  &.margin-top {
    margin-top: $form-spacing;
  }

  &.margin-bottom {
    margin-bottom: $form-spacing;
  }
}

.alert {
  color: get-color(alert);
}

.bold {
  font-weight: bold;
}

span.times {
  &::before {
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1;
    transform: translateY(-2px);
    content: 'x';
    display: inline-block;
  }
}

.vas-banderole,
.company-value-added-step {
  border: 1px solid $highlight-color;
  color: $highlight-color;
  display: inline-block;
  padding: 0.25rem 0.35rem 0.15rem;
  line-height: 1;
  font-size: 0.8rem;
}

.no-margin {
  margin: 0;
}

.no-wrap {
  white-space: nowrap !important;
}

.badge {
  display: inline-block;
  min-width: 1.75em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.75rem;
  text-align: center;
  background: $primary-color;
  color: #fff;
}

i {
  &.alert {
    color: get-color(alert);
  }

  &.success {
    color: get-color(success);
  }

  &.warning {
    color: get-color(warning);
  }

  &.info {
    color: get-color(info);
  }

  &.primary {
    color: get-color(primary);
  }

  &.highlight {
    color: $highlight-color;
  }
}

a {
  outline: 0;

  &.alert {
    color: get-color(alert);
    transition: color 0.25s ease-out;

    &:hover {
      color: scale-color(get-color(alert), $lightness: -15%);
    }
  }

  &.success {
    color: get-color(success);
    transition: color 0.25s ease-out;

    &:hover {
      color: scale-color(get-color(success), $lightness: -15%);
    }
  }

  &:not(.button).highlight {
    color: $highlight-color;
    transition: color 0.25s ease-out;

    &:hover {
      color: scale-color($highlight-color, $lightness: -14%);
    }
  }

  &.underline {
    text-decoration: underline;
  }
}

.fa-user-circle-o {
  &[data-abo-model-id="4"] {
    color: $abo-bronze-color;
  }

  &[data-abo-model-id="2"] {
    color: $abo-silver-color;
  }

  &[data-abo-model-id="3"] {
    color: $abo-gold-color;
  }
}

// old pagination
*:not(.dataTables_paginate)>ul.pagination {
  list-style: none;

  &>li {
    &>a {
      padding: 0.25rem 0.25rem;
      border-radius: 3px;
    }

    &:not(.arrow) {
      margin: 0 0.25rem;

      &>a {
        color: $body-font-color;

        &:hover {
          background-color: $primary-color;
          color: $white;
        }
      }
    }

    &:not(.arrow).current>a {
      background-color: $primary-color;
      color: $white;
    }
  }
}

// uic-single-select START
.uic-single-select-drop {
  border: 1px solid $medium-gray;
  border-radius: $global-radius;
  margin-bottom: rem-calc(16);
}

.uic-single-select-drop.uic-expanded .uic-selection {
  background: transparent;
}

.uic-single-select-drop.uic-expanded .uic-menu {
  border-bottom-right-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
}

// uic-single-select END

/* uic-pretty-[checkbox|radio] START */
.uic-pretty-checkbox+label,
.uic-pretty-radio+label {
  display: inline;
}

// uic-single-select-drop / uic-multi-checkbox START
.uic-multi-checkbox-dropdown {
  border-color: $medium-gray;
  border-radius: $global-radius;
  margin-bottom: rem-calc(16);

  .uic-scrollable {
    @include overflowScrollY();

    label {
      font-weight: normal;
    }
  }
}

.uic-single-select-drop .uic-menu,
.uic-multi-checkbox-dropdown .uic-menu {
  font-size: 0.8rem;
}

.uic-single-select-drop .uic-menu,
.uic-single-select-drop.uic-expanded,
.uic-multi-checkbox-dropdown.uic-expanded,
.uic-multi-checkbox-dropdown[data-uic-dropdown-style=none] {
  border-color: $medium-gray;
}

.uic-multi-checkbox-dropdown.uic-expanded .uic-label,
.uic-multi-checkbox-dropdown[data-uic-dropdown-style=none] .uic-label {
  background: transparent;
}

.uic-multi-checkbox-dropdown.uic-expanded .uic-menu,
.uic-multi-checkbox-dropdown[data-uic-dropdown-style=none] .uic-menu {
  border-bottom-right-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
  border-color: $medium-gray;
}

// uic-multi-checkbox END

// Tabs START
.tabs-container .tab-label {
  font-size: 0.9rem;
}

// Tabs END

// Modal Window START
.modal-window {
  .modal-head {
    background-color: $light-gray;

    h4 {
      margin-bottom: 0;
    }
  }

  .modal-body {
    min-height: 100px;
  }
}

// Modal Window END

// jCrop Plugin START
.jcrop-holder {
  z-index: 1;
}

// jCrop Plugin END

.glide {
  .glide__slide {
    height: auto;
  }
}

// Flatpickr START
.flatpickr-input {
  &+input[readonly],
  &[readonly] {
    background-color: $input-background;
    cursor: pointer;
  }
}

.flatpickr-calendar.open {
  z-index: 2147483001 !important; // due to Userlike!
}

[id^=userlike-] {
  iframe {
    z-index: 9;
  }
}

// Flatpickr END

// Bio Icon START
.bio-icon {
  position: absolute;
  display: inline-block;
  z-index: 2;
  top: 8px;
  left: -3px;
  box-shadow: 1px 1px 1px 0 rgba(darken(get-color(success), 20%), 0.8);
  vertical-align: middle;
  padding: 4px 5px 3px;
  background: get-color(success);
  color: $white;
  font-size: 13px;
  line-height: 13px;
  font-weight: normal;
  white-space: nowrap;

  &.inline {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    box-shadow: none;
  }
}

// Bio Icon END

// Blazy START
.b-lazy {
  min-width: 1px;
  min-height: 1px;
}
// Blazy END

// Flag Icon START
// .flag-icon {
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.33);
// }
// Flag Icon END

// ZF6 Accordion START
.accordion-item {
  border-bottom: 1px dotted $medium-gray;

  &:first-of-type {
    border-top: 1px dotted $medium-gray;
  }

  .accordion-title {
    border: 0;
  }

  &.is-active {
    .accordion-title {
      background-color: $mundus-agri-color-light;
    }
  }
}

// ZF6 Accordion END

// Input with icon START
.input-wrap {
  position: relative;

  input {
    padding-right: 2rem;
    margin: 0;
  }

  i {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: $medium-gray;
    pointer-events: none;
    font-style: normal;
  }
}
// Input with icon END

// Input with unit START
.field-with-unit {
  flex: 1;
  display: flex;
  background-color: $white;

  label {
    flex: 0 1 auto;
    border: 1px solid $medium-gray;
    border-left: 0;
    margin: 0;
    padding: 0 $form-spacing/2;
    transition: $input-transition;
    align-items: center;
    color: $medium-gray;
    display: flex;
    font-size: 0.9rem;
    line-height: 1.2;
  }

  input {
    min-width: 50px;
    flex: 1;
    border-right: 0!important;

    &:disabled+label {
      background-color: lighten($light-gray, 3%);
    }

    &:focus+label {
      border-color: $dark-gray;
    }
  }
}

.inactive-link {
   pointer-events: none;
   cursor: default;
}
// Input with unit END