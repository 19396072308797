@import '../mixins';

html.modal-open {
  body {
    overflow-y: hidden;
  }
}

@keyframes showModalWindow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-window {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.33);
  overflow-y: auto;

  &.modal-absolute {
    position: absolute;
  }

  .modal-inner {
    width: calc(100% - 2rem);
    max-height: 100%;
    display: block;
    margin: 1rem auto;
    position: relative;
  }

  .modal-content {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .modal-head,
  .modal-body,
  .modal-footer {
    width: 100%;
    position: relative;
    padding: 1rem;
  }

  .modal-head,
  .modal-footer {
    flex: 0 1 auto;
    background-color: $light-gray;
  }

  .modal-body {
    flex: 1 1 auto;
  }

  &.full-height {
    justify-content: center;
    padding: 1rem 0;

    .modal-inner {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .modal-content {
      flex: 1;
    }
  }

  &.modal-open {
    display: flex;
    animation-name: showModalWindow;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
  }

  &.contain {
    padding: 1rem 0;

    &.modal-open {
      display: flex;
    }

    .modal-inner {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    .modal-head {
      flex: 0 1 auto;
    }

    .modal-body {
      max-height: 100%;
      @include overflowScrollY();
    }

    .modal-content {
      flex: 1;
      max-height: 100%;
      overflow: hidden;
    }

    .modal-footer {
      flex: 0 1 auto;
    }
  }

  .scrollable {
    @include overflowScrollY();
  }

  &.medium {
    .modal-inner {
      max-width: map-get($breakpoints, medium);
    }
  }

  &.mlarge {
    .modal-inner {
      max-width: map-get($breakpoints, mlarge);
    }
  }

  &.large {
    .modal-inner {
      max-width: map-get($breakpoints, large);
    }
  }
}