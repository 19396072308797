.component.offers {
  .component-item {
    display:flex;
    font-size:0.8rem;
    font-weight:bold;
    line-height:1.1;
   
    &:not(:last-of-type) {
      border-bottom:1px dotted $medium-gray;
      margin-bottom:0.5rem;
    }
    
    >div:first-of-type {
      padding-right:0.5rem;
    }
    
    .offer-date {
      font-weight:normal;
      font-size:0.7rem;
      margin-top:0.25rem;
    }
  }
  
  .offer-image-container img {
    max-width:40px;
  }
}