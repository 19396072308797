#dedere-header {
  .dh-toggle.messages {
    .num-messages {
      background-color: get-color(alert);
      color: $white;
      border-radius: 2px;
      padding: 2px;
      line-height: 1;
      font-size: 10px;
      position: absolute;
      display: none;
      top: -5px;
      left: calc(100% - 8px);
    }

    .date-time {
      color: $medium-gray;
    }

    &.has-messages .num-messages {
      display: block;
    }
  }

  .dh-flyout.messages {
    .dh-flyout-content {
      position: relative;
      min-height: 2.5rem;

      .no-results-message {
        white-space: normal;
        cursor: default;
        font-size: 14px;
        padding: 0.5rem;
      }

      &>a:not([href]) .action {
        color: $body-font-color;
      }

      .add-message {
        padding: 0.5rem
      }
    }

    &.maximized {
      position: fixed !important;
      top: 1rem !important;
      left: 1rem !important;
      width: calc(100% - 2rem) !important;
      margin: auto !important;
      max-width: 1000px;
      max-height: calc(100% - 2rem) !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      z-index: 6;

      .dh-flyout-head {
        font-size: 1.94444rem;
        padding: 1rem;
        align-items: center;

        b {
          font-weight: 300;
          @include thinHeadline();
        }

        a {
          font-size: 1.5rem;
        }
      }

      .dh-flyout-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: $white;
        padding: 1rem;
        border: 0;
        box-shadow: 0 0 1px 0 $light-gray;
        @include overflowScrollY();
      }

      &::before {
        content: '';
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.33);
      }
    }

    &:not(.maximized) {
      .pagination {
        // margin: 0;
        // padding: 1rem 0;
        // background-color: $light-gray;
        // border-top: 1px solid $medium-gray;
      }
    }
  }
}