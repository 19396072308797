/* Cookie Notice START */
#cookie-notice {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($black, 0.8);
  color: #fff;
  transform: translateY(-100%);

  .inner {
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.unconfirmed {
    animation-name: showCookieNotice;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
  }

  &.confirmed {
    animation-name: hideCookieNotice;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
    transform: translateY(0);
  }

  p {
    margin: 0;
  }

  .button {
    flex: 0 1 auto;
    margin-left: 1rem;
  }
}

@keyframes showCookieNotice {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hideCookieNotice {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

/* Cookie Notice END */