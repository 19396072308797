.dh-flyout.admin {
  .dh-flyout-content {
    a,
    a:not([href]) {
      cursor: pointer;
      user-select: none;
    }

    i {
      min-width: 1.5rem;
      text-align: center;
      margin-right: 5px;
    }

    ul>li>a {
      display: block;
      padding: 0.5rem;
      text-align: left;
    }

    .submenu {
      margin-bottom: 1rem;
    }

    .is-submenu-item a {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    .is-accordion-submenu a:after {
      border-color: $body-font-color transparent transparent;
    }

    .bo-accordion-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;

      >li {
        flex: 0 0 100%;
        border-bottom: 1px dotted $medium-gray;

        a {
          border-bottom: 0;
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }

    .bo-menu-item {
      a {
        display: block;
        width: 100%;
      }
    }

    .has-submenu {
      >a {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translate(0, -2px) rotate(0);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 3px 0 3px;
          border-color: $primary-color transparent transparent transparent;
        }
      }

      &.is-open>a {
        font-weight: bold;

        &:after {
          transform: translate(0, -2px) rotate(180deg);
        }
      }
    }

    .bo-submenu {
      list-style: none;
      padding: 0 0 10px 0;
      margin: -8px 0 0 0;
      text-align: left;

      li {
        border-bottom: 0;
      }

      a {
        color: $primary-color;
        font-size: 0.9em;
        padding: 0.25rem 1rem;
      }

      a.bo-submenu-title i {
        display: none;
      }

      .bo-submenu-title {
        color: $body-font-color;
        font-weight: bold;
        cursor: default !important;
      }
    }

    .bo-submenu-2nd-level {
      list-style: none;
      margin-left: 0.5rem;
    }
  }

  @include breakpoint(480px) {
    .bo-accordion-menu {
      >li {
        flex: 0 0 calc(50% - 0.5rem);
        max-width: calc(50% - 0.5rem);

        &:nth-of-type(odd):nth-last-child(2) {
          border-bottom: 0;
        }
      }
    }
  }
}

@media (max-width:900px) and (max-height:1000px) {
  .dh-flyout.admin {
    .dh-flyout-content {
      max-height: 20rem;
      @include overflowScrollY();
    }
  }
}

@media (max-width:900px) and (max-height:800px) {
  .dh-flyout.admin {
    .dh-flyout-content {
      max-height: 18rem;
      @include overflowScrollY();
    }
  }
}

@media (max-width:900px) and (max-height:600px) {
  .dh-flyout.admin {
    .dh-flyout-content {
      max-height: 16rem;
      @include overflowScrollY();
    }
  }
}

@media (max-width:900px) and (max-height:400px) {
  .dh-flyout.admin {
    .dh-flyout-content {
      max-height: 13rem;
      @include overflowScrollY();
    }
  }
}

@media (min-width:900px) and (min-height:600px) {
  .dh-flyout.admin {
    max-width: 680px;

    >ul>li>a {
      padding: 0.5rem 1rem;
    }
  }
}